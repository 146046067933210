.select {
  display: block;
  width: 600px;
  padding: 8px 16px;
  font: 400 16px/1.5 'Noto Sans', sans-serif;
  background: #f0e9e7;
  border: 1px solid #e2d6cfff;
  border-radius: 12px;
  box-shadow: inset 1px 1px 0 #f7f4f2;
  margin: 0 auto;
  cursor: pointer; }
