@import "src/index";
.login {
  flex: 1;
  text-align: center;
  padding-top: 175px;
  &__wrap {
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center; }
  &__logo {
    width: 115px;
    height: auto;
    margin-bottom: 24px; }
  &__title {
    font: 500 46px var(--main-font);
    margin-bottom: 12px; }
  &__text {
    font: 400 18px var(--main-font);
    opacity: .6;
    margin-bottom: 36px;
    max-width: 483px; }
  .main-btn {
    position: relative;
    font: 500 16px var(--main-font);
    padding: 9px 30px;
    background-clip: padding-box;
    border: 1px solid transparent;
    border-radius: 7px;
    background: var(--bg-color);
    transition: background .25s;
    &__border {
      position: absolute;
      background: linear-gradient(to right, var(--accent-color-1) 0%, var(--accent-color-2) 100%);
      @include size(100%);
      top: 0;
      left: 0;
      border-radius: inherit; }
    &__bg {
      position: absolute;
      width: calc(100% - 2px);
      height: calc(100% - 2px);
      top: 1px;
      left: 1px;
      background-color: var(--bg-color);
      border-radius: inherit;
      z-index: 0;
      transition: opacity .25s; }
    &__text {
      position: relative;
      background: linear-gradient(to right, var(--accent-color-1) 0%, var(--accent-color-2) 100%);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      z-index: 1; }
    &:after {
      content: '';
      position: absolute;
      top: -4px;
      left: -4px;
      width: calc(100% + 4px);
      height: calc(100% + 4px);
      border: 2px solid transparent;
      transition: border .25s; }
    &:hover, &.focus-visible {
      .main-btn__bg {
        opacity: 0; }
      .main-btn__text {
        background: initial;
        -webkit-background-clip: unset;
        -webkit-text-fill-color: unset; } }
    &:focus {
      outline: none; }
    &.focus-visible {
      &:after {
        border: 2px solid var(--focus-color); } } } }

