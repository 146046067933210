@import "src/sass/vars";
.transfer-ownership {
  display: flex;
  flex-direction: column;
  flex: 1;
  padding-top: 104px;
  .transfer-ownership-card {
    height: auto;
    margin: 0 auto;
    --padding: 16px;
    &__header {
      position: relative;
      padding: 17px 16px;
      text-align: center;
      .back-btn-wrap {
        position: absolute;
        top: 50%;
        left: 16px;
        transform: translateY(-50%); }
      .title {
        display: inline-block;
        font: 500 16px var(--main-font);
        color: var(--text-color-6); } }
    &__body {
      padding: 16px;
      .input__container {
        margin-bottom: 20px;
        &:nth-child(2) {
          margin-bottom: 28px; } } } } }
