@import "src/sass/vars";
.header {
  padding-top: 20px;
  &__wrap {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 100%; }
  &__logo-wrap, .account {
    width: calc(33.33%); }
  &__logo {
    display: inline-block;
    font: 600 34px/1.5 var(--main-font);
    @include gradient(right, var(--accent-color-1), var(--accent-color-2));
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent; }
  .account {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    &__name {
      margin-right: 10px;
      font: 400 18px/27px var(--main-font);
      color: var(--text-color);
      opacity: 0.6;
      cursor: pointer; } } }
