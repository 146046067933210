@import "src/sass/vars";
.loader {
  @include size(48px);
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-content: space-between;
  margin: auto;
  &__item {
    border-radius: 2px;
    @include size(20px);
    background-color: var(--accent-color-1);
    animation: loader-item-anim 2s infinite;
    @for $i from 1 through 4 {
      &:nth-child(#{$i}) {
        animation-delay: ($i / 4) + s; } }
    &:nth-child(3) {
      order: 4; }
    &:nth-child(4) {
      order: 3; } } }

@keyframes loader-item-anim {
  0% {
    opacity: 0; }
  50% {
    opacity: 1; }
  100% {
    opacity: 0; } }
