.pie-icon {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--icon-bg-color);
  border-radius: 50%;
  padding: 10%;
  svg {
    width: 100%;
    height: auto; } }
