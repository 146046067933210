@import "src/sass/vars";

.accent-btn {
  position: relative;
  width: 100%;
  background: linear-gradient(to right, var(--accent-color-1) 0%, var(--accent-color-2) 100%);
  padding: 9px;
  font: 500 16px var(--main-font);
  color: var(--bg-color-8);
  border-radius: 7px;
  transition: opacity .25s;
  &:after {
    content: '';
    position: absolute;
    top: -4px;
    left: -4px;
    width: calc(100% + 4px);
    height: calc(100% + 4px);
    border: 2px solid transparent;
    transition: border .25s; }
  &:focus {
    outline: none; }
  &.focus-visible {
    &:after {
      border: 2px solid var(--focus-color); } }
  &:disabled {
    cursor: default;
    opacity: .35; }
  &:active,
  &:hover {
    opacity: .85; } }

.primary-btn {
  position: relative;
  width: 100%;
  min-height: 38px;
  background-color: var(--accent-color-1);
  padding: 2px;
  font: 500 14px var(--main-font);
  color: var(--bg-color);
  border-radius: 5px;
  transition: opacity .25s;
  &:disabled {
    cursor: default;
    opacity: .6; }
  &:after {
    content: '';
    position: absolute;
    top: -4px;
    left: -4px;
    width: calc(100% + 4px);
    height: calc(100% + 4px);
    border: 2px solid transparent;
    transition: border .25s; }
  &:focus {
    outline: none; }
  &.focus-visible {
    &:after {
      border: 2px solid var(--focus-color); } }
  &:active,
  &:hover {
    opacity: .9; } }

.transfer-ownership-btn {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--accent-color-1-3);
  color: var(--accent-color-1);
  transition: opacity .25s, background-color .25s, color .25s;
  &:disabled {
    cursor: default;
    opacity: .5; }
  &:active {
    opacity: .8; }
  &:hover {
    background-color: var(--accent-color-1-5);
    opacity: .8; }
  &:after {
    content: '';
    position: absolute;
    top: -4px;
    left: -4px;
    width: calc(100% + 4px);
    height: calc(100% + 4px);
    border: 2px solid transparent;
    transition: border .25s; }
  &:focus {
    outline: none; }
  &.focus-visible {
    &:after {
      border: 2px solid var(--focus-color); } } }

.simple-btn {
  position: relative;
  display: inline-block;
  font: 400 14px var(--main-font);
  padding: 1px 12px;
  margin-left: auto;
  border-radius: 5px;
  background-color: var(--text-color-15);
  color: var(--text-color);
  transition: background-color .25s, color .25s, opacity .25s;
  &:active,
  &:hover {
    background-color: var(--text-color-25); }
  &:disabled {
    cursor: default;
    opacity: .5; }
  &:after {
    content: '';
    position: absolute;
    top: -4px;
    left: -4px;
    width: calc(100% + 4px);
    height: calc(100% + 4px);
    border: 2px solid transparent;
    transition: border .25s; }
  &:focus {
    outline: none; }
  &.focus-visible {
    &:after {
      border: 2px solid var(--focus-color); } } }

.clear-btn {
  display: inline-block;
  position: relative;
  font: 500 14px var(--main-font);
  color: var(--warning-color);
  height: 30px;
  margin-left: auto;
  transition: color .25s;
  &:hover,
  &:active {
    color: var(--warning-color-8); }
  &:after {
    content: '';
    position: absolute;
    top: -4px;
    left: -4px;
    width: calc(100% + 4px);
    height: calc(100% + 4px);
    border: 2px solid transparent;
    transition: border .25s; }
  &:disabled {
    cursor: default;
    color: var(--text-color-3); }
  &:focus {
    outline: none; }
  &.focus-visible {
    &:after {
      border: 2px solid var(--focus-color); } } }
