.notification {
  position: fixed;
  width: 356px;
  top: 92px;
  right: 30px;
  border-radius: 10px;
  padding: 20px;
  background-color: var(--notification-bg-color);
  color: var(--text-color-6);
  &__text {
    font: 400 16px var(--main-font);
    width: calc(100% - 28px); }
  &__close-btn {
    position: absolute;
    top: 20px;
    right: 20px; } }
