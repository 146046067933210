@import "src/sass/vars";
.icon-button {
  @include flex(column, center, center);
  cursor: pointer;
  position: relative;
  border-radius: 2px;
  padding: 0;
  transition: background .25s;
  svg {
    width: 100%;
    height: auto;
    fill: var(--text-color-4);
    transition: fill .25s; }
  &:hover,
  &:active {
    background: #c4c4c4;
    svg {
      fill: var(--bg-color); } }
  &:focus {
    outline: none; }
  &:after {
    content: '';
    position: absolute;
    top: -4px;
    left: -4px;
    width: calc(100% + 4px);
    height: calc(100% + 4px);
    border: 2px solid transparent;
    transition: border .25s; }
  &.focus-visible {
    &:after {
      border: 2px solid var(--focus-color); } }
  &.action {
    svg {
      fill: var(--text-color-8); }
    &:active,
    &:hover {
      background: transparent;
      svg {
        fill: var(--text-color-6); } } }
  &:disabled {
    cursor: default;
    svg {
      fill: var(--text-color-4); } } }

