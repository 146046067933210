@import "sass/css-reset";
@import "sass/vars";
@import "sass/theme";
@import "sass/buttons";

html, body, #root {
  width: 100%;
  height: 100%;
  font: 400 16px/1.5 var(--main-font);
  background-color: var(--bg-color);
  color: var(--text-color);
  -webkit-font-smoothing: antialiased; }

.wrapper {
  --padding: 30px;
  width: 100%;
  max-width: calc(1440px + var(--padding));
  margin: 0 auto;
  padding: 0 var(--padding);
  @include small {
    --padding: 16px; } }

.js-focus-visible :focus:not(.focus-visible) {
  outline: none; }

.main-card {
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 343px;
  height: 438px;
  border-radius: 10px;
  background-color: var(--card-bg-color); }
