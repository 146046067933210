@import "src/sass/vars";
.input {
  display: block;
  width: 100%;
  padding: 8px;
  border-radius: 4px;
  font: 400 12px var(--main-font);
  background-color: var(--input-bg-color);
  color: var(--text-color-8);
  border: 1px solid transparent;
  transition: border .25s;
  @include placeholder {
    font: 400 12px var(--main-font);
    color: var(--text-color-2); }
  &.focus-visible {
    outline: none;
    border-color: var(--text-color-3); }
  &__element {
    position: relative;
    display: block; }
  &__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 6px; }
  &__label {
    display: block;
    font: 500 12px var(--main-font);
    color: var(--text-color-5); }
  &__label-right {
    font: 400 12px var(--main-font);
    color: var(--text-color-6);
    margin-left: auto; }
  &__wrap {
    display: block;
    position: relative; }
  &__suffix {
    position: absolute;
    right: 12px;
    bottom: 9px;
    font: 500 12px var(--main-font);
    color: var(--text-color-7); }
  &__error {
    border-color: var(--warning-color); }
  &__error-message {
    font: 400 9px var(--main-font);
    color: var(--warning-color);
    margin-top: 3px; } }
