@import "src/sass/vars";

.send-token {
  display: flex;
  flex-direction: column;
  flex: 1;
  padding-top: 104px;
  .send-token-card {
    height: auto;
    margin: 0 auto;
    --padding: 16px;
    &__header {
      position: relative;
      padding: 17px 16px;
      .back-btn-wrap {
        position: absolute;
        top: 50%;
        left: 16px;
        transform: translateY(-50%); }
      .token {
        display: flex;
        align-items: center;
        justify-content: center;
        &__symbol {
          font: 500 16px var(--main-font);
          color: var(--text-color-6); }
        &__icon {
          @include size(18px);
          margin-right: 4px; } } }
    &__body {
      padding: 16px;
      .input__container {
        margin-bottom: 20px;
        &:nth-child(2) {
          margin-bottom: 28px; } } } } }
