@import "src/sass/vars";
.delegate {
  display: flex;
  flex-direction: column;
  flex: 1;
  padding-top: 104px;
  .delegate-card {
    height: 584px;
    margin: 0 auto;
    --padding: 16px;
    &__header {
      position: relative;
      padding: 17px 16px;
      text-align: center;
      .back-btn-wrap {
        position: absolute;
        top: 50%;
        left: 16px;
        transform: translateY(-50%); }
      .address {
        display: inline-block;
        font: 500 16px var(--main-font);
        color: var(--text-color);
        margin-right: 7px;
        opacity: .6;
        cursor: pointer; } }
    .address__wrap {
      display: flex;
      align-items: center;
      justify-content: center; }
    &__info {
      display: flex;
      align-items: flex-start;
      padding: 16px 16px 20px;
      border-bottom: 1px solid var(--main-separator-color);
      .icon {
        margin-right: 12px; }
      .info, .action, .part {
        margin-top: 7px; }
      .info {
        .title {
          font: 500 20px var(--main-font);
          opacity: .9; }
        .desc {
          font: 400 10px var(--main-font);
          opacity: .4;
          max-width: 160px;
          margin-top: 2px; } }
      .action {
        margin-left: auto;
        &:disabled {
          color: #eee; } }
      .part {
        font: 400 20px var(--main-font);
        color: var(--text-color);
        height: 30px;
        margin-left: auto;
        opacity: .9; } }
    .empty-tokens {
      font: 400 20px var(--main-font);
      margin: 24px 16px 16px;
      opacity: .8; }
    .scroll {
      position: relative;
      width: 100%;
      flex: 1;
      overflow-y: auto; } }
  .not-owner-info {
    padding: 16px;
    border-bottom: 1px solid var(--main-separator-color);
    .icon {
      @include size(24px);
      object-fit: contain;
      margin-right: 12px; }
    .title {
      font: 400 16px var(--main-font);
      color: var(--text-color-7); }
    .desc {
      font: 500 10px var(--main-font);
      color: var(--text-color-4);
      max-width: 144px; }
    .part {
      margin-left: auto;
      font: 500 16px var(--main-font);
      color: var(--text-color-9); }
    &__header {
      display: flex;
      align-items: flex-start;
      margin-bottom: 12px; }
    &__actions {
      display: flex;
      margin-bottom: 24px;
      .spacer {
        width: 10px; } }
    &__action {
      flex: 1;
      font: 500 14px var(--main-font);
      min-height: 32px;
      padding: 2px;
      text-align: center;
      border-radius: 5px; }
    .transfer-ownership-btn {
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: var(--accent-color-1-3);
      color: var(--accent-color-1);
      &:disabled {
        cursor: default;
        opacity: .5; } }
    &__footer {
      display: flex;
      align-items: flex-start; } }
  &__tokens {
    padding: 0 16px;
    position: absolute;
    top: 0;
    left: 0;
    @include size(100%); }
  &__token {
    display: flex;
    align-items: center;
    padding: 16px 0;
    border-bottom: 1px solid var(--separator-color);
    .token-action {
      //display: inline-block
      margin-left: auto;
      &:active {
        background-color: var(--text-color-25); }
      &:disabled {
        cursor: default;
        opacity: .5; } } }
  &__token-icon {
    display: block;
    @include size(32px);
    margin-right: 10px; }
  &__token-info {}
  &__token-name {
    font: 500 14px/1 var(--main-font);
    margin-bottom: 4px;
    color: var(--text-color);
    opacity: .8; }
  &__token-balances,
  &__token-balance {
    display: flex;
    align-items: center;
    .icon {
      margin-right: 5px; }
    .balance {
      font: 400 9px var(--main-font);
      opacity: .4; } } }
