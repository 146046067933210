@import "src/sass/vars";
.delegates {
  flex: 1;
  padding-top: 104px;
  &-card {
    margin: 0 auto;
    --padding: 16px;
    &__body {
      display: flex;
      flex-direction: column;
      flex: 1; }
    &__scroll {
      position: relative;
      width: 100%;
      flex: 1;
      overflow-y: auto; }
    &__title {
      font: 400 26px var(--main-font);
      padding: 16px 16px 12px;
      border-bottom: 1px solid var(--main-separator-color); }
    &__delegates {
      position: absolute;
      top: 0;
      left: 0;
      @include size(100%);
      padding: 0 0 16px; }
    &__delegate {
      &:last-child {
        .delegates-card__link-body {
          border-bottom: none; } }
      .delegate__icon {
        display: flex;
        align-items: center;
        justify-content: center;
        @include size(44px);
        background-color: var(--icon-bg-color);
        border-radius: 50%;
        margin-right: 12px;
        & > * {
          width: 80%;
          height: auto; } } }
    &__link {
      display: block;
      padding: 0 16px;
      transition: background-color .25s, box-shadow .25s;
      &:hover, &:active {
        background-color: var(--text-color-05); }
      &:focus {
        outline: none; }
      &.focus-visible {
        box-shadow: inset 0 0 0 2px var(--focus-color); } }
    &__link-body {
      display: flex;
      align-items: center;
      padding: 16px 0;
      border-bottom: 1px solid var(--separator-color); }
    &__empty {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      flex: 1;
      text-align: center;
      font: 400 26px var(--main-font);
      padding: 0 var(--padding); }
    .create-delegate-btn {
      margin: auto var(--padding) 16px;
      width: calc(100% - (var(--padding) * 2)); } } }

