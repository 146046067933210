@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600&display=swap');
 :root {
  --bg-color: #14161B;
  --bg-color-8: rgba(20, 22, 27, .8);
  --text-color: #ffffff;
  --text-color-05: rgba(255, 255, 255, .05);
  --text-color-1: rgba(255, 255, 255, .1);
  --text-color-15: rgba(255, 255, 255, .15);
  --text-color-2: rgba(255, 255, 255, .2);
  --text-color-25: rgba(255, 255, 255, .25);
  --text-color-3: rgba(255, 255, 255, .3);
  --text-color-4: rgba(255, 255, 255, .4);
  --text-color-5: rgba(255, 255, 255, .5);
  --text-color-6: rgba(255, 255, 255, .6);
  --text-color-7: rgba(255, 255, 255, .7);
  --text-color-8: rgba(255, 255, 255, .8);
  --text-color-9: rgba(255, 255, 255, .9);
  --input-bg-color: rgba(255, 255, 255, .06);
  --main-font: 'Poppins', sans-serif;
  --accent-color-1: #08AEEA;
  --accent-color-1-3: rgba(8, 174, 234, .3);
  --accent-color-2: #2AF598;
  --card-bg-color: #1C1D23;
  --focus-color: #00B2FF;
  --separator-color: rgba(255, 255, 255, .1);
  --main-separator-color: rgba(255, 255, 255, .25);
  --icon-bg-color: #44475A;
  --warning-color: #EB5757;
  --warning-color-8: rgba(235, 87, 87, .8);
  --notification-bg-color: #2A2C36; }

