$main-font: 'Yanone Kaffeesatz', sans-serif;

// my-mixins

@mixin flex($direction, $justify, $align) {
  display: flex;
  flex-direction: $direction;
  justify-content: $justify;
  align-items: $align; }

@mixin card_shadow {
  box-shadow: 0 4px 8px -7px rgba(0,0,0,.2); }

@mixin gradient($way, $color1, $color2) {
  background: $color1;
  background: linear-gradient(to $way, $color1 0%, $color2 100%); }

@mixin size($size) {
  width: $size;
  height: $size; }

@mixin custom_bg($size) {
  background-color: transparent;
  background-size: $size;
  background-position: center center;
  background-repeat: no-repeat; }

@mixin clipping_text() {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis; }

@mixin square() {
  &:after {
    content: "";
    display: block;
    padding-top: 100%; } }

@mixin placeholder() {
  &::-webkit-input-placeholder {
    @content; }
  &:-moz-placeholder {
    @content; }
  &::-moz-placeholder {
    @content; }
  &:-ms-input-placeholder {
    @content; } }

@mixin media($size) {
  @media screen and (max-width: $size+px) {
    @content; } }

@mixin large() {
  @media screen and (max-width: 1400px) {
    @content; } }

@mixin medium() {
  @media screen and (max-width: 992px) {
    @content; } }

@mixin small() {
  @media screen and (max-width: 768px) {
    @content; } }

@mixin extra_small() {
  @media screen and (max-width: 576px) {
    @content; } }
